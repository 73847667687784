import axios from 'axios';
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../layout/routes';
import { UNIVERSAL_ADMIN } from '../helpers/constants';

const IS_SEO_FILTER_21010 = process.env.REACT_APP_FF_FORMA_21522 === 'true';

const useSeoShortUrl = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (IS_SEO_FILTER_21010) {
      const currentPath = pathname.slice(1);

      axios
        .get(`${UNIVERSAL_ADMIN}/seo_filters`)
        .then(res => {
          res?.data?.forEach(item => {
            if (item?.shortUrl === currentPath) {
              axios
                .get(`${UNIVERSAL_ADMIN}/seo-page/${currentPath}`)
                .then(res => {
                  const params = [];
                  res?.data?.characteristics.forEach(char => {
                    if (char?.value === '') params.push({ key: 'advantages', value: char?.key });
                    else params.push({ key: char?.key, value: char?.value });
                  });

                  const path = params?.reduce((acc, cur) => {
                    const string = cur?.key + '=' + cur?.value;
                    if (acc === '') acc += string;
                    else acc += '&' + string;
                    return acc;
                  }, '');

                  navigate(`${ROUTES.flats.list}?${path}`);
                })
                .catch(error => console.error(error));
            }
          });
        })
        .catch(error => console.error(error));
    }
  }, []);

  return null;
};

export default useSeoShortUrl;
