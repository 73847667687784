import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchNavList = createAsyncThunk('termsSlice/fetchNavList', async (_, thunkApi) => {
  return fetchData(api.urlNavList, thunkApi);
});

export const fetchTradeIn = createAsyncThunk('termsSlice/fetchTradeIn', async (_, thunkApi) => {
  return fetchData(api.urlTradeIn, thunkApi);
});

export const fetchQa = createAsyncThunk('termsSlice/fetchQa', async (_, thunkApi) => {
  return fetchData(api.urlQa, thunkApi);
});

export const fetchSpecialMortgages = createAsyncThunk('termsSlice/fetchSpecialMortgages', async (_, thunkApi) => {
  return fetchData(api.urlSpecialMortgages, thunkApi);
});

export const fetchMortgages = createAsyncThunk('termsSlice/fetchMortgages', async (_, thunkApi) => {
  return fetchData(api.urlMortgages, thunkApi);
});

export const fetchCalcData = createAsyncThunk('termsSlice/fetchCalcData', async (_, thunkApi) => {
  return fetchData(api.urlCalcData, thunkApi);
});

export const fetchInstallments = createAsyncThunk('termsSlice/fetchInstallments', async (_, thunkApi) => {
  return fetchData(api.urlInstallments, thunkApi);
});

export const fetchSpecialInstallments = createAsyncThunk('termsSlice/fetchSpecialInstallments', async (_, thunkApi) => {
  return fetchData(api.urlSpecialInstallments, thunkApi);
});

export const fetchCash = createAsyncThunk('termsSlice/fetchCash', async (_, thunkApi) => {
  return fetchData(api.urlCash, thunkApi);
});

export const fetchBuyout = createAsyncThunk('termsSlice/fetchBuyout', async (_, thunkApi) => {
  return fetchData(api.urlBuyout, thunkApi);
});

export const fetchCashback = createAsyncThunk('termsSlice/fetchCashback', async (_, thunkApi) => {
  return fetchData(api.urlCashback, thunkApi);
});

export const fetchPromoCards = createAsyncThunk('termsSlice/fetchPromoCards', async (_, thunkApi) => {
  return fetchData(api.urlPromoCards, thunkApi);
});

export const fetchReferralProgram = createAsyncThunk('termsSlice/fetchReferralProgram', async (_, thunkApi) => {
  return fetchData(api.urlReferralProgram, thunkApi);
});

const initialState = {
  navList: null,
  navListError: false,
  tradeIn: null,
  tradeInError: false,
  qa: null,
  qaError: false,
  specialMortgages: null,
  specialMortgagesError: false,
  mortgages: null,
  mortgagesError: false,
  calcData: null,
  calcDataError: false,
  installments: null,
  installmentsError: false,
  specialInstallments: null,
  specialInstallmentsError: false,
  cash: null,
  cashError: false,
  flats: null,
  flatsError: false,
  cashback: null,
  cashbackError: false,
  buyout: null,
  buyoutError: false,
  promoCards: null,
  promoCardsError: false,
  activeTab: 0,
  referralProgram: null,
  referralProgramError: false,
};

export const termsSlice = createSlice({
  name: 'termsSlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: {
    [fetchNavList.fulfilled]: (state, action) => {
      state.navList = action.payload;
    },
    [fetchNavList.rejected]: (state, action) => {
      state.navListError = true;
    },

    [fetchTradeIn.fulfilled]: (state, action) => {
      state.tradeIn = action.payload;
    },
    [fetchTradeIn.rejected]: (state, action) => {
      state.tradeInError = true;
    },

    [fetchQa.fulfilled]: (state, action) => {
      state.qa = action.payload;
    },
    [fetchQa.rejected]: (state, action) => {
      state.qaError = true;
    },

    [fetchSpecialMortgages.fulfilled]: (state, action) => {
      state.specialMortgages = action.payload;
    },
    [fetchSpecialMortgages.rejected]: (state, action) => {
      state.specialMortgagesError = true;
    },

    [fetchMortgages.fulfilled]: (state, action) => {
      state.mortgages = action.payload;
    },
    [fetchMortgages.rejected]: (state, action) => {
      state.mortgagesError = true;
    },

    [fetchCalcData.fulfilled]: (state, action) => {
      state.calcData = action.payload;
    },
    [fetchCalcData.rejected]: (state, action) => {
      state.calcDataError = true;
    },

    [fetchInstallments.fulfilled]: (state, action) => {
      state.installments = action.payload;
    },
    [fetchInstallments.rejected]: (state, action) => {
      state.installmentsError = true;
    },

    [fetchSpecialInstallments.fulfilled]: (state, action) => {
      state.specialInstallments = action.payload;
    },
    [fetchSpecialInstallments.rejected]: (state, action) => {
      state.specialInstallmentsError = true;
    },

    [fetchCash.fulfilled]: (state, action) => {
      state.cash = action.payload;
    },
    [fetchCash.rejected]: (state, action) => {
      state.cashError = true;
    },

    [fetchBuyout.fulfilled]: (state, action) => {
      state.buyout = action.payload;
    },
    [fetchBuyout.rejected]: (state, action) => {
      state.buyoutError = true;
    },

    [fetchCashback.fulfilled]: (state, action) => {
      state.cashback = action.payload;
    },
    [fetchCashback.rejected]: (state, action) => {
      state.cashbackError = true;
    },

    [fetchPromoCards.fulfilled]: (state, action) => {
      state.promoCards = action.payload;
    },
    [fetchPromoCards.rejected]: (state, action) => {
      state.promoCardsError = true;
    },

    [fetchReferralProgram.fulfilled]: (state, action) => {
      state.referralProgram = action.payload;
    },
    [fetchReferralProgram.rejected]: (state, action) => {
      state.referralProgramError = true;
    },
  },
});

export const { setActiveTab } = termsSlice.actions;

export const navListSelector = state => state.termsInfo.navList;
export const navListErrorSelector = state => state.termsInfo.navListError;
export const calcDataErrorSelector = state => state.termsInfo.calcDataError;
export const calcDataSelector = state => state.termsInfo.calcData;
export const specialInstallmentsSelector = state => state.termsInfo.specialInstallments;
export const specialInstallmentsErrorSelector = state => state.termsInfo.specialInstallmentsError;
export const installmentsSelector = state => state.termsInfo.installments;
export const installmentsErrorSelector = state => state.termsInfo.installmentsError;
export const cashSelector = state => state.termsInfo.cash;
export const cashErrorSelector = state => state.termsInfo.cashError;
export const cashbackSelector = state => state.termsInfo.cashback;
export const cashbackErrorSelector = state => state.termsInfo.cashbackError;
export const buyoutSelector = state => state.termsInfo.buyout;
export const buyoutErrorSelector = state => state.termsInfo.buyoutError;
export const promoCardsSelector = state => state.termsInfo.promoCards;
export const promoCardsErrorSelector = state => state.termsInfo.promoCardsError;
export const mortgagesSelector = state => state.termsInfo.mortgages;
export const mortgagesErrorSelector = state => state.termsInfo.mortgagesError;
export const specialMortgagesSelector = state => state.termsInfo.specialMortgages;
export const specialMortgagesErrorSelector = state => state.termsInfo.specialMortgagesError;
export const qaSelector = state => state.termsInfo.qa;
export const qaErrorSelector = state => state.termsInfo.qaError;
export const tradeInSelector = state => state.termsInfo.tradeIn;
export const tradeInErrorSelector = state => state.termsInfo.tradeInError;
export const activeTabSelector = state => state.termsInfo.activeTab;
export const referralProgramSelector = state => state.termsInfo.referralProgram;
export const referralProgramErrorSelector = state => state.termsInfo.referralProgramError;

export default termsSlice.reducer;
